<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">提现设置</p>
		</div>
		<a-divider style="margin-left: -15px" />
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
			<a style="color: #E0352B;margin-left: 40px;">设置为0代表不限制金额</a>
			<a-form-model-item label="最少提现额度" required prop="least_withdrawal_money">
				<a-input-number v-model="form.least_withdrawal_money" :min="0" /> 元
			</a-form-model-item>
			<a style="color: #E0352B;margin-left: 40px;">设置为0代表不限制金额</a>
			<a-form-model-item label="每日提现上限" prop="day_withdrawal_limit">
				<a-input-number v-model="form.day_withdrawal_limit" :min="0" /> 元
			</a-form-model-item>
			<a-form-model-item :wrapper-col="{ span: 14, offset: 1 }">
				<a-button type="primary" @click="onSubmit">
					提交
				</a-button>
				<a-button style="margin-left: 10px;" @click="cancel">
					取消
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>
<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			labelCol: { span: 2 },
			wrapperCol: { span: 14 },
			other: '',
			form: {
				least_withdrawal_money: 0,
				day_withdrawal_limit: 0
			},
			rules: {
				least_withdrawal_money: [
					{
						required: true,
						message: '填写最少提现额度',
						trigger: 'blur'
					}
				],
				day_withdrawal_limit: [
					{
						required: true,
						message: '填写每日提现上限',
						trigger: 'blur'
					}
				],
			},
		};
	},
	created() {
		this.getInfo()
	},
	methods: {
		onSubmit() {
			this.$post(domain + '/admin/v1/reconciliation/withdrawalConfig', this.form).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getInfo() {
			this.$post(domain + '/admin/v1/reconciliation/withdrawalConfigInfo', {}).then(res => {
				if (res.code === 0) {
					this.form.least_withdrawal_money = res.data.least_withdrawal_money
					this.form.day_withdrawal_limit = res.data.day_withdrawal_limit
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		cancel() {
			this.$message.warn('取消我应该怎么做呢？')
		},
	},
};
</script>
